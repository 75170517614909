import React, { useEffect, useRef } from 'react';
import { Card, CardBody, Col, Row, Button, ButtonGroup  } from 'reactstrap';
import List from 'list.js';
import { ToolTips } from '../tooltips/ToolTips';

export const ListCards = (props) => {


    const {
        filterProps = null, type = "list", inputFiter = false, items, handleUpdate = null, handleDelete = null, methodsAbsolutes = null
    } = props;


    const refList = useRef(null);

    useEffect(() => {
        if (items.length > 0) {
            new List(refList.current, {
                valueNames: filterProps,
                listClass: type
            });
        }
    }, [items]);



    return (
        <>
            <div ref={refList}>
                <Row>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3">
                        {
                            inputFiter ?  <input className="form-control form-control-sm search" placeholder="Buscar" /> : ''
                        }
                    </Col>
                </Row>
                <br />
                <Row className={ type }>
                    {
                        items.length > 0
                            ?
                                items.map((data, key) => {
                                    return (
                                        <Col md="6" sm="12" xl="3" key={key}>
                                            <Card className="">
                                                <div style={{backgroundColor:"#0079C7"}} className={ "nameBrowser ribbon ribbon-clip ribbon-left ribon-success primary" }>
                                                    {data.name}                                                   
                                                </div>
                                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                                    <ButtonGroup >
                                                        <Button outline className="btn btn-air-info" color="info" size="xs" onClick={() => handleUpdate(data.id_rate)}>
                                                            <i className="icofont icofont-ui-edit f-14"></i>
                                                        </Button>
                                                        {
                                                            methodsAbsolutes !== null &&
                                                            methodsAbsolutes.map( (obj, key) => {
                                                                return(
                                                                    <Button type="button" key={"btn-"+key} id={`btnRate-${data.id_rate}-${key}`} outline className="btn btn-air-info" color={ obj.colorBtn } size="xs" onClick={ () => obj.method(data.id_rate) }>
                                                                        <i className={ obj.icon }></i>
                                                                        <ToolTips
                                                                            dataPlacement="top"
                                                                            dataTarget={`btnRate-${data.id_rate}-${key}`}
                                                                            dataText={obj.titleToolTip}
                                                                        />
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                        <Button outline className="btn btn-air-info" color="danger" size="xs" onClick={() => handleDelete(data.id_rate)}>
                                                            <i className="icofont icofont-ui-delete f-14"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                                <Col sm="12"> <br />
                                                    <div className="profile-vector" style={{ textAlign: "-webkit-center" }}>
                                                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Rates/Rates.svg")} style={{ width: "40%" }} alt="" />
                                                    </div>
                                                </Col> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="6" style={{ textAlign :"center" }}>
                                                        <label className='f-12 text-secondary'>Clave</label> <br />
                                                            <label className='f-12'>{ data.abbreviation }</label>
                                                        </Col>
                                                        <Col sm="6" style={{ textAlign :"center" }}>
                                                        <label className=' text-secondary f-12'>Razón social</label> <br />
                                                            <label className='f-12'>{data.short_name_commercial_line}</label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Card>
                                        </Col>
                                    )
                                })
                            :
                                null
                    }                    
                </Row>
            </div>
        </>
    )
}
