import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody, Col, Row, Badge, Label, Tooltip, ButtonGroup } from 'reactstrap'
//import { ToolTips } from '../tooltips/ToolTips';
import { ToolTips } from '../../components/tooltips/ToolTips';
// import './styleCard.css';
import List from 'list.js';
import moment from 'moment';


export const ListCardDiscount = (props) => {


    const {
        filterProps = null, type = "list", inputFiter = false, items, handleAuthorized, handleUpdate, handleDelete, handleDesAuthorized
    } = props;


    const refList = useRef(null);

    useEffect(() => {
        if (items.length > 0) {
            new List(refList.current, {
                valueNames: filterProps,
                listClass: type
            });
        }
    }, [items]);

    const date_now = moment();


    return (
        <>
            <div ref={refList}>
                <Row>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3">
                        {
                            inputFiter ? <input className="form-control form-control-sm search" placeholder="Buscar" /> : ''
                        }
                    </Col>
                </Row>  <br /> <br />
                <Row className={type}>
                    {
                        items.length > 0 ?
                            items.map((obj, key) => {
                                console.log("🚀 ~ items.map ~ obj:", obj)
                                return (
                                    <Col xs="12" sm="12" md="4" lg="4" xl="4" key={key} className='box mb-1' >
                                        <div className="project-box p-3 ribbon-wrapper-left">
                                            <div className={`ribbon ribbon-clip ribbon-${moment(date_now).isBetween(obj.valid_from, obj.valid_to) ? "aprov" : "danger"}`}>
                                                {moment(date_now).isBetween(obj.valid_from, obj.valid_to) ? "Activa" : "Inactiva"}
                                            </div>
                                            <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                                <ButtonGroup>
                                                    {
                                                        obj.userAuthorized === "" &&
                                                        <Button
                                                            outline className="btn btn-air-info"
                                                            color="success" size="xs"
                                                            onClick={() => handleAuthorized(obj.id_common_discount)}>
                                                            <i
                                                                id={`tooltip-authorized-${key}`}
                                                                className="icofont icofont-ui-lock"
                                                            >
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget={`tooltip-authorized-${key}`}
                                                                    dataText={'Autorizar'}
                                                                />
                                                            </i>
                                                        </Button>
                                                    }
                                                    {
                                                        obj.userAuthorized !== "" && 
                                                        <Button
                                                            outline className="btn btn-air-warning"
                                                            color="warning" size="xs"
                                                            onClick={() => handleDesAuthorized(obj.id_common_discount)}>
                                                            <i
                                                                id={`tooltip-authorized-${key}`}
                                                                className="icofont icofont-ui-unlock"
                                                            >
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget={`tooltip-authorized-${key}`}
                                                                    dataText={'Desautorizar'}
                                                                />
                                                            </i>
                                                        </Button>

                                                    }
                                                    {
                                                        <Button
                                                            outline className="btn btn-air-info"
                                                            color="info"
                                                            size="xs"
                                                            onClick={() => handleUpdate(obj.id_common_discount, (obj.userAuthorized === "" ? "edit" : "view"))}>
                                                            <i
                                                                id={`tooltip-edit-${key}`}
                                                                className={`icofont ${obj.userAuthorized === "" ? "icofont-ui-edit" : "icofont-eye-alt"}`}
                                                            >
                                                                {
                                                                    obj.userAuthorized === "" ?
                                                                        <ToolTips
                                                                            placement="top"
                                                                            dataTarget={`tooltip-edit-${key}`}
                                                                            dataText={'Editar'}
                                                                        />
                                                                        :
                                                                        <ToolTips
                                                                            placement="top"
                                                                            dataTarget={`tooltip-edit-${key}`}
                                                                            dataText={'Visualizar'}
                                                                        />
                                                                }
                                                            </i>
                                                        </Button>
                                                    }
                                                    <Button
                                                        outline className="btn btn-air-info" color="danger" size="xs" onClick={() => handleDelete(obj.id_common_discount)}>
                                                        <i
                                                            id={`tooltip-delete-${key}`}
                                                            className="icofont icofont-ui-delete"
                                                        >
                                                            <ToolTips
                                                                placement="top"
                                                                dataTarget={`tooltip-delete-${key}`}
                                                                dataText={'Eliminar'}
                                                            />
                                                        </i>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            {/* <br /> <br /> */}
                                            <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                                                <h6 className='name'>{obj.name} <br /> <label className='text-secondary'>({obj.code})</label></h6>
                                            </Col> <br />
                                            {
                                                moment(obj.valid_from).format("DD-MM-YYYY") === moment(obj.valid_to).format("DD-MM-YYYY") ?
                                                    <p className="f-14">{`Promoción activa solo el: ${moment(obj.valid_from).format("DD-MM-YYYY")} `}</p>
                                                    :
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col sm="6">
                                                                <label >{`Inicia: ${moment(obj.valid_from).format("DD-MM-YYYY")}`}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label >{`termina: ${moment(obj.valid_to).format("DD-MM-YYYY")}`}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                            }
                                            <Row>
                                                <Col sm="6"> <br />
                                                    <div className="media">
                                                        <img className="img-35 mr-1 rounded-circle"
                                                            src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} style={{ width: "24%" }}
                                                            alt="" />
                                                        <div className="media-body">
                                                            <p className="m-0 p-0">Creado por:</p>
                                                            <p>{obj.userCreated}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="6"> <br />
                                                    <div className="media text-right">
                                                        <div className="media-body">
                                                            {
                                                                obj.userAuthorized !== "" ?
                                                                    <>
                                                                        <p className="m-0 p-0">Autorizado por: &nbsp;</p>
                                                                        <p>{obj.userAuthorized}</p>
                                                                    </>
                                                                    :
                                                                    <p>Sin autorizar &nbsp;</p>
                                                            }
                                                        </div>
                                                        <img className="img-35 mr-1 rounded-circle"
                                                            src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount2.svg")} style={{ width: "24%" }}
                                                            alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <small>
                                                        <i className="fa fa-calendar text-info f-16" ></i> <label className='f-12'>Días activos: </label>&nbsp;&nbsp;
                                                        {
                                                            obj.days_of_week === "todos" ?
                                                                "Todos los días de la semana" :
                                                                obj.days_of_week.split(",").map(x => {
                                                                    switch (x) {
                                                                        case "1":
                                                                            return "Lunes"
                                                                        case "2":
                                                                            return "Martes"
                                                                        case "3":
                                                                            return "Miercoles"
                                                                        case "4":
                                                                            return "Jueves"
                                                                        case "5":
                                                                            return "Viernes"
                                                                        case "6":
                                                                            return "Sabado"
                                                                        default:
                                                                            return "Domingo"
                                                                    }
                                                                }).join(",")
                                                        }
                                                    </small>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12"> <br />
                                                    <small>
                                                        <i className="fa fa-bookmark text-primary f-16"></i> <label className='f-12'> Dirigido a :   </label>  &nbsp;
                                                        {
                                                            obj.discountCondition.id_gender === null ?
                                                                "Todas las personas "
                                                                :
                                                                (
                                                                    obj.discountCondition.id_gender === 1 ? "Mujeres" : "Hombres"
                                                                )
                                                        }
                                                        {
                                                            (obj.discountCondition.day_from === 0 && obj.discountCondition.day_to === 0) ? " sin importar la edad"
                                                                :
                                                                (obj.discountCondition.day_from > 0 && obj.discountCondition.day_to > 0) ?
                                                                    ` desde ${obj.discountCondition.day_from / 365} año(s) hasta ${obj.discountCondition.day_to / 365} año(s)`
                                                                    :
                                                                    (obj.discountCondition.day_from === 0 && obj.discountCondition.day_to > 0) ?
                                                                        ` menores a ${obj.discountCondition.day_to / 365} año(s) `
                                                                        : ` mayores a ${obj.discountCondition.day_from / 365} año(s) `
                                                        }
                                                    </small>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })
                            :
                            <Col style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "25%" }} alt="" />   <br />
                                <br /> <p className="f-18">{"No se ha registrado una Campaña de descuento aún."}</p>
                            </Col>
                    }
                </Row>
            </div>
        </>
    )
}
